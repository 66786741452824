import { MouseEvent, MouseEventHandler } from 'react'
import { Modal } from './modal'
import './message-modal.scss'

interface MessageModalProps {
    title: string
    message: string
    okTitle?: string
    cancelTitle?: string
    onCloseClick?: MouseEventHandler
    onOkClick?: MouseEventHandler
    onCancelClick?: MouseEventHandler
}

export const MessageModal = (props: MessageModalProps) => {
    return (
        <Modal title={props.title} onCloseClick={props.onCloseClick} onOkClick={props.onOkClick} okTitle={props.okTitle} onCancelClick={props.onCancelClick} cancelTitle={props.cancelTitle} >
            <label className="message-modal__message">{props.message}</label>
        </Modal>
    )
}
