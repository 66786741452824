import { useDispatch, useSelector } from 'react-redux'
import { ChangeEvent, createRef, MouseEvent, KeyboardEvent, useEffect, useState } from 'react'
import { SearchField } from '../../component/field'
import { exportDealerships, getDealerships } from '../../action'
import { DealershipTable } from './component'
import { CREATE_DEALERSHIP_ACTIONS, GET_DEALERSHIPS_ACTIONS } from '../../action/dealerships/dealerships.constants'
import { useTranslation } from 'react-i18next'
import { Dealership } from '../../entity'
import { AddDealershipModal } from './component/add-dealership-modal'
import './dealerships.page.scss'
import { State } from '../../state'

interface DealershipsPageProps {}

export const DealershipsPage = (props: DealershipsPageProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [addDealershipModalVisible, setAddDealershipModalVisible] = useState(false)
    const [selectedDealership, setSelectedDealership] = useState(null as Dealership | null)

    const {  search } = useSelector((state: State) => state.dealerships)

    const onSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        dispatch({ type: GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_SEARCH, search: event.target.value })

        if (event.target.value.length == 0) {
            await getDealerships()
        }
    }

    const onSearchButtonClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()

        await getDealerships()
    }

    const onExportClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()

        await exportDealerships()
    }

    const onAddDealershipButtonClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()
        setAddDealershipModalVisible(!addDealershipModalVisible)
    }

    const onAddDealershipModalClose = () => {
        setSelectedDealership(null)
        setAddDealershipModalVisible(!addDealershipModalVisible)
    }

    return (
        <main className="page page-dealerships">
            <div className="main-div">
                <div className="navigation-bar-div">
                    <div className="navigation-bar-title-div">
                        <h1> {t('dealerships_title')}</h1>
                        <p> {t('dealerships_description')}</p>
                    </div>
                    <div className="navigation-bar-button-div">
                        <button className="button" onClick={onExportClick}>
                            {t('dealerships_export_button')}
                        </button>

                        <button className="button" onClick={onAddDealershipButtonClick}>
                            {t('dealerships_add_button')}
                        </button>
                    </div>
                </div>

                <div className="search-bar-div">
                    <SearchField  value={search} onSearch={onSearchButtonClick} onChange={onSearchChange} placeholder={t('search_placeholder')} />

                    <button className="button" onClick={onSearchButtonClick}>
                        {t('search_button')}
                    </button>
                </div>

                <DealershipTable
                    onDealershipSelected={(dealership: Dealership) => {
                        setSelectedDealership(dealership)
                        setAddDealershipModalVisible(!addDealershipModalVisible)
                    }}
                />
            </div>

            {addDealershipModalVisible ? (
                <AddDealershipModal
                    dealership={selectedDealership}
                    onModalClose={onAddDealershipModalClose}
                    onCloseClick={() => {
                        dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CLEAR })

                        setSelectedDealership(null)

                        setAddDealershipModalVisible(!addDealershipModalVisible)
                    }}
                />
            ) : null}
        </main>
    )
}
