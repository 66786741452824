import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { DefaultDTO, ErrorDTO, UserDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { DELETE_USER_ACTIONS, LOGOUT_ACTIONS } from './users.constants'
import { createCall } from '../base'
import { getAllUser } from './get-all-user.action'

export const deleteUser = async (id: number, isMe: boolean) => {
    store.dispatch({ type: DELETE_USER_ACTIONS.DELETE_USER_ACTIONS_STARTED })

    const promise = createCall(`users/${id}`, true, BASE_METHODS.DELETE)
    promise
        .then(async (response) => {
            let body: DefaultDTO

            try {
                body = plainToClass(DefaultDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: DELETE_USER_ACTIONS.DELETE_USER_ACTIONS_OK })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })

            if (!isMe) {
                getAllUser()
            } else {
                store.dispatch({ type: LOGOUT_ACTIONS.LOGOUT })
            }
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: DELETE_USER_ACTIONS.DELETE_USER_ACTIONS_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
