import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../component'
import { State } from '../../../state'
import { getDealerships } from '../../../action'
import { useNavigate } from 'react-router-dom'
import { GET_DEALERSHIPS_ACTIONS } from '../../../action/dealerships/dealerships.constants'
import { useTranslation } from 'react-i18next'
import { Sort } from '../../../enum/sort.enum'
import './dealership-table.scss'

interface DealershipTableProps {
    onDealershipSelected?: CallableFunction
}

export const DealershipTable = (props: DealershipTableProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { dealerships, order, sort } = useSelector((state: State) => state.dealerships)
    const { page, pageSize, count } = useSelector((state: State) => state.dealerships.pagination)

    const onPageChange = async (page: number) => {
        dispatch({ type: GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_CHANGE_PAGE, page: page })
        await getDealerships()
    }

    const onOrderChange = async (column: string) => {
        let direction = Sort.ASC

        if (order === column) {
            if (sort === Sort.ASC) {
                direction = Sort.DESC
            } else if (sort === Sort.DESC) {
                column = ``
                direction = Sort.EMPTY
            }
        }

        dispatch({ type: GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_CHANGE_ORDER, orderBy: column, sort: direction })
        await getDealerships()
    }

    const columns = [
        {
            canOrder: true,
            caption: t('dealership_table_name_title'),
            render: (row: any) => row.serviceCode + ' - ' + row.serviceName,
            name: `dealership.serviceCode`,
            width: 262,
        },
        {
            canOrder: true,
            caption: t('dealership_table_country_title'),
            render: (row: any) => row.country,
            name: `country.name`,
            width: 262,
        },
        {
            canOrder: false,
            caption: t('dealership_table_dealers_title'),
            render: (row: any) => (row.users != null ? row.users.length : 0),
            name: `users`,
            width: 262,
        },
        {
            canOrder: true,
            caption: t('dealership_table_status_title'),
            render: (row: any) => (row.status == 1 ? t('status_active_title') : t('status_deactivated_title')),
            name: `dealership.status`,
            width: 262,
        },
    ]

    return (
        <Table
            className="dealership-table"
            columns={columns}
            dataset={dealerships !== null && dealerships !== undefined ? dealerships : []}
            pagination={{ page: page, pageSize: pageSize, count: count, onPageChange: onPageChange }}
            order={{ orderBy: order, sort: sort }}
            onOrderChange={onOrderChange}
            onRowClick={(row) => {
                if (props.onDealershipSelected) props.onDealershipSelected(row)
            }}
        />
    )
}
