import { useDispatch, useSelector } from 'react-redux'
import { ChangeEvent, MouseEvent, KeyboardEvent } from 'react'
import { SearchField } from '../../component/field'
import { WarrantyGroupsTable } from './component'
import { GET_WARRANTY_GROUPS_ACTIONS } from '../../action/warranties/warranties.constants'
import { getWarrantyGroups } from '../../action'
import { useTranslation } from 'react-i18next'
import { State } from '../../state'
import './warranties.page.scss'

interface WarrantyGroupPageProps {}

export const WarrantyGroupPage = (props: WarrantyGroupPageProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { search } = useSelector((state: State) => state.warrantyGroup)

    const onSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_SEARCH, search: event.target.value })

        if (event.target.value.length == 0) {
            await getWarrantyGroups()
        }
    }

    const onSearchButtonClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()

        await getWarrantyGroups()
    }

    return (
        <main className="page page-warranties">
            <div className="main-div">
                <div className="navigation-bar-div">
                    <h1>{t('warranty_groups_title')}</h1>
                    <p>{t('warranty_groups_description')}</p>
                </div>

                <div className="search-bar-div">
                    <SearchField value={search} onSearch={onSearchButtonClick} onChange={onSearchChange} placeholder={t('search_placeholder')} />

                    <button className="button" onClick={onSearchButtonClick}>
                        {t('search_button')}
                    </button>
                </div>

                <WarrantyGroupsTable />
            </div>
        </main>
    )
}
