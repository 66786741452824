import { MouseEvent, MouseEventHandler, useState } from 'react'
import { Modal } from '../../../component/modal'
import { Cover, Photo } from '../../../entity'
import { useTranslation } from 'react-i18next'
import { PhotoEditor } from './editor/photo-editor'
import { useDispatch, useSelector } from 'react-redux'
import { PHOTO_EDITOR_ACTIONS } from '../../../action/photos/photos.constants'
import { State } from '../../../state'
import './edit-photo-modal.scss'
import { getWarranty } from '../../../action'

interface EditPhotoModalProps {
    warrantyId: number

    photo: Photo
    onCloseClick:()=> void
    onCancelCallback: CallableFunction
    onAcceptCallback: CallableFunction
}

export const EditPhotoModal = (props: EditPhotoModalProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [actualCover, setActualCover] = useState<Cover | null>(null)

    const { acceptCover, isToolsEnabled } = useSelector((state: State) => state.editor)

    const onCancelButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        { !isToolsEnabled ? (acceptCover != null ? onDeleteAllCovers() : props.onCancelCallback()) : onRemoverLastCover() }
    }

    const onAcceptButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        { !isToolsEnabled ? props.onAcceptCallback() : onAcceptCover() }
    }

    const onAcceptCover = async () => {
        if(actualCover != null){
            dispatch({ type: PHOTO_EDITOR_ACTIONS.ADD_COVER, cover: actualCover })
        }

        dispatch({ type: PHOTO_EDITOR_ACTIONS.SELECTED_TOOL, tool: null })
        dispatch({ type: PHOTO_EDITOR_ACTIONS.IS_TOOLS_ENABLED, enabled: false })
        dispatch({ type: PHOTO_EDITOR_ACTIONS.ACCEPT_COVER, acceptCover: false })

        setActualCover(null)

        await getWarranty(props.warrantyId)

    }

    const onRemoverLastCover = () => {
        dispatch({ type: PHOTO_EDITOR_ACTIONS.REMOVE_COVER })
        dispatch({ type: PHOTO_EDITOR_ACTIONS.ACCEPT_COVER, acceptCover: false })
        dispatch({ type: PHOTO_EDITOR_ACTIONS.SELECTED_TOOL, tool: null })
        dispatch({ type: PHOTO_EDITOR_ACTIONS.IS_TOOLS_ENABLED, enabled: false })

        setActualCover(null)
    }

    const onDeleteAllCovers = () => {
        dispatch({ type: PHOTO_EDITOR_ACTIONS.DELETE_ALL_COVER })
        dispatch({ type: PHOTO_EDITOR_ACTIONS.SELECTED_TOOL, tool: null })
        dispatch({ type: PHOTO_EDITOR_ACTIONS.IS_TOOLS_ENABLED, enabled: false })
        dispatch({ type: PHOTO_EDITOR_ACTIONS.ACCEPT_COVER, acceptCover: null })

        setActualCover(null)
    }

    return (
        <Modal title={t('photo_info_modal_title') + ' ' + props.photo.id} onCloseClick={()=>{
                dispatch({ type: PHOTO_EDITOR_ACTIONS.ACCEPT_COVER, acceptCover: null })
                props.onCloseClick()}
            }>
            <label className="time">{t('edit_photo_modal_description')}</label>

            <PhotoEditor photo={props.photo} editable={true}  onDrawEnd={(cover: Cover) => {
                setActualCover(cover)
                //dispatch({ type: PHOTO_EDITOR_ACTIONS.ADD_COVER, cover: cover })
            }}/>

            <div className={`edit-photo-modal__buttons`}>
                <button className="button" onClick={onCancelButtonClick}>
                    {isToolsEnabled ? t('edit_photo_modal_delete_cover_button') : acceptCover != null ? t('edit_photo_modal_delete_all_cover_button') : t('button_cancel')}
                </button>

                <button className="button" onClick={onAcceptButtonClick}>
                    {isToolsEnabled ? t('edit_photo_modal_place_cover_button') : acceptCover != null ? t('edit_photo_modal_accept_photo_button') : t('edit_photo_modal_accept_covering_button')}
                </button>
            </div>
        </Modal>
    )
}
