import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { MouseEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { WarrantyCard } from './component/warranty-card'
import { createFormattedDateString } from '../../helper'
import { useTranslation } from 'react-i18next'
import { WarrantyStatus } from '../../enum/warranty-status.enum'
import { updateWarrantyStatus } from '../../action'
import { MessageModal } from '../../component/modal'
import './warranty-detail.page.scss'
import { UserType } from '../../enum/user-type.enum'
import { Segment } from '../../enum/segment.enum'

interface WarrantyDetailPageProps {}

export const WarrantyDetailPage = (props: WarrantyDetailPageProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { id } = useParams()

    const [reopenModalVisible, setReopenModalVisible] = useState(false)

    const { warranty } = useSelector((state: State) => state.warranty)
    const { authenticatedUser } = useSelector((state: State) => state.session)

    const onDoneButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (authenticatedUser?.role != UserType.REVIEWER) {
            if (warranty!!.status == WarrantyStatus.ACCEPTED) {
                setReopenModalVisible(!reopenModalVisible)
            } else {
                await updateWarrantyStatus(warranty!!.id, WarrantyStatus.ACCEPTED)
            }
        }
    }

    console.log(authenticatedUser?.role != UserType.REVIEWER)

    return (
        <main className="page page-warranty-detail">
            <div className="main-div">
                <div className="navigation-bar-div">
                    <h1>
                        {t('warranty_detail_first_title')} {warranty?.vinNumber} - {t('warranty_detail_second_title')} {id}
                    </h1>
                    <p>{warranty?.segment == Segment.MARINE ? t('warranty_detail_engine_description').replace('{VIN szám}', warranty?.vinNumber ?? '') : t('warranty_detail_description').replace('{VIN szám}', warranty?.vinNumber ?? '')}</p>
                </div>

                {authenticatedUser?.role != UserType.REVIEWER ? (
                    <div className="action-bar-div">
                        <button className="button" onClick={onDoneButtonClick}>
                            {warranty?.status == WarrantyStatus.NEW ? t('warranty_detail_done_button') : warranty?.status == WarrantyStatus.RE_OPEN ? t('warranty_detail_done_button') : t('warranty_detail_reopen_button')}
                        </button>
                    </div>
                ) : null}

                <div className="title-bar-div">
                    <div className="header-container">
                        <span>{t('warranties_table_number_of_photos_title')}</span>
                        <span>{t('warranties_table_creation_title')}</span>
                        <span>{t('warranties_table_type_title')}</span>
                        <span>{t('warranties_table_dealership_title')}</span>
                        <span>{t('warranties_table_country_title')}</span>
                    </div>

                    <div className="body-container">
                        <div className="status-div">
                            <div className={warranty?.status == WarrantyStatus.ACCEPTED ? 'status-accepted' : 'status-reopen'} />
                            <span>{warranty?.photos?.length} </span>
                        </div>
                        <span>{createFormattedDateString(warranty ? warranty?.created : 0)}</span>
                        <span>{warranty?.segment == 1 ? t('segment_automobile_title') : warranty?.segment == 2 ? t('segment_motorcycle_title') : t('segment_marine_title')}</span>
                        <span>{warranty?.dealership?.serviceCode + ' - ' + warranty?.dealership?.serviceName} </span>
                        <span>{warranty?.dealership?.country} </span>
                    </div>
                </div>

                <div className="photos-container">
                    {(() => {
                        let cards = []

                        if (warranty != null && warranty.photos != null) {
                            for (let i = 0; i < warranty.photos.length; i++) {
                                cards.push(<WarrantyCard photo={warranty.photos[i]} warrantyStatus={warranty.status} warrantyId={warranty.id} />)
                            }
                        }

                        return cards
                    })()}
                </div>
            </div>

            {reopenModalVisible ? (
                <MessageModal
                    title={t('reopen_warranty_modal_title')}
                    message={t('reopen_warranty_modal_message')}
                    onCloseClick={() => {
                        setReopenModalVisible(!reopenModalVisible)
                    }}
                    onCancelClick={() => {
                        setReopenModalVisible(!reopenModalVisible)
                    }}
                    onOkClick={async () => {
                        await updateWarrantyStatus(warranty!!.id, WarrantyStatus.RE_OPEN)

                        setReopenModalVisible(!reopenModalVisible)
                    }}
                />
            ) : null}
        </main>
    )
}
