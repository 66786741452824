import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO, WarrantiesDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { GET_WARRANTY_ACTIONS } from './warranties.constants'
import { WarrantyDTO } from '../../dto/warranties'

export const getWarranty = async (id: number) => {
    store.dispatch({ type: GET_WARRANTY_ACTIONS.GET_WARRANTY_STARTED })

    const promise = createCall(`warranties/${id}`, true, BASE_METHODS.GET)
    promise
        .then(async (response) => {
            let body: WarrantyDTO

            try {
                body = plainToClass(WarrantyDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: GET_WARRANTY_ACTIONS.GET_WARRANTY_OK, warranty: body.warranty })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: GET_WARRANTY_ACTIONS.GET_WARRANTY_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
