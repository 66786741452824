import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO, CountriesDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { GET_COUNTRIES_ACTIONS } from '../dealerships/dealerships.constants'

export const getCountries = async (search:string ) => {
    store.dispatch({ type: GET_COUNTRIES_ACTIONS.GET_COUNTRIES_ACTIONS_STARTED })

    const promise = createCall(`countries/${search != '' ? `?search=${search}` : ``}`, true, BASE_METHODS.GET)
    promise
        .then(async (response) => {
            let body: CountriesDTO

            try {
                body = plainToClass(CountriesDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: GET_COUNTRIES_ACTIONS.GET_COUNTRIES_ACTIONS_OK, countries : body.countries})

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: GET_COUNTRIES_ACTIONS.GET_COUNTRIES_ACTIONS_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
