import { GET_WARRANTIES_ACTIONS } from '../action/warranties/warranties.constants'
import { WarrantiesState } from '../state/warranties.state'

const initialWarrantiesState: WarrantiesState = {
    warranties: [],
    pagination: {
        page: 1,
        pageSize: 0,
        count: 0,
    },
    search: '',
    order: 'created',
    sort: 'DESC',
}

export const warrantiesReducer = (state: WarrantiesState = initialWarrantiesState, action: any): WarrantiesState => {
    switch (action.type) {
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_OK:
            return { ...state, warranties: action.warranties, pagination: { ...state.pagination, page: action.page, pageSize: action.pageSize, count: action.count } }
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } }
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_CHANGE_ORDER:
            return { ...state, order: action.orderBy, sort: action.sort }
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_SEARCH:
            return { ...state, pagination: { ...state.pagination, page: 1 }, search: action.search }
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_FAILED:
            return { ...state }
        default:
            return state
    }
}
