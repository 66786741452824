export const GET_WARRANTIES_ACTIONS = {
    GET_WARRANTIES_CHANGE_PAGE: 'GET_WARRANTIES_CHANGE_PAGE',
    GET_WARRANTIES_CHANGE_ORDER: 'GET_WARRANTIES_CHANGE_ORDER',
    GET_WARRANTIES_CHANGE_SEGMENT: 'GET_WARRANTIES_CHANGE_SEGMENT',
    GET_WARRANTIES_SEARCH: 'GET_WARRANTIES_SEARCH',
    GET_WARRANTIES_STARTED: 'GET_WARRANTIES_STARTED',
    GET_WARRANTIES_OK: 'GET_WARRANTIES_OK',
    GET_WARRANTIES_FAILED: 'GET_WARRANTIES_FAILED',
}

export const GET_WARRANTY_GROUPS_ACTIONS = {
    GET_WARRANTY_GROUPS_CHANGE_PAGE: 'GET_WARRANTY_GROUPS_CHANGE_PAGE',
    GET_WARRANTY_GROUPS_CHANGE_ORDER: 'GET_WARRANTY_GROUPS_CHANGE_ORDER',
    GET_WARRANTY_GROUPS_CHANGE_SEGMENT: 'GET_WARRANTY_GROUPS_CHANGE_SEGMENT',
    GET_WARRANTY_GROUPS_SEARCH: 'GET_WARRANTY_GROUPS_SEARCH',
    GET_WARRANTY_GROUPS_STARTED: 'GET_WARRANTY_GROUPS_STARTED',
    GET_WARRANTY_GROUPS_OK: 'GET_WARRANTY_GROUPS_OK',
    GET_WARRANTY_GROUPS_FAILED: 'GET_WARRANTY_GROUPS_FAILED',
}

export const GET_WARRANTY_ACTIONS = {
    GET_WARRANTY_STARTED: 'GET_WARRANTY_STARTED',
    GET_WARRANTY_OK: 'GET_WARRANTY_OK',
    GET_WARRANTY_FAILED: 'GET_WARRANTY_FAILED',
}

export const UPDATE_WARRANTY_STATUS_ACTIONS = {
    UPDATE_WARRANTY_STATUS_STARTED: 'GET_WARRANTY_STARTED',
    UPDATE_WARRANTY_STATUS_OK: 'GET_WARRANTY_OK',
    UPDATE_WARRANTY_STATUS_FAILED: 'GET_WARRANTY_FAILED',
}
