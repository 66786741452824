import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO, UsersDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { GET_ALL_USER_ACTIONS } from './users.constants'

export const getAllUser = async () => {
    store.dispatch({ type: GET_ALL_USER_ACTIONS.GET_ALL_USER_STARTED })

    const role = store.getState().users.role
    const page = store.getState().users.pagination.page
    const search = store.getState().users.search
    const orderBy = store.getState().users.order
    const sort = store.getState().users.sort

    const promise = createCall(`users/?page=${page}${role != null ? `&role=${role}` : ``}&pageSize=${15}${search != '' ? `&search=${search}` : ``}&orderBy=${orderBy}&sort=${sort}`, true, BASE_METHODS.GET)
    promise
        .then(async (response) => {
            let body: UsersDTO

            try {
                body = plainToClass(UsersDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: GET_ALL_USER_ACTIONS.GET_ALL_USER_OK, users: body.data, page: body.page, pageSize: body.pageSize, count: body.count })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: GET_ALL_USER_ACTIONS.GET_ALL_USER_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
