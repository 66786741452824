import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../component'
import { State } from '../../../state'
import { getWarranties } from '../../../action'
import { GET_WARRANTIES_ACTIONS } from '../../../action/warranties/warranties.constants'
import { createFormattedDateString } from '../../../helper'
import { useNavigate } from 'react-router-dom'
import { Sort } from '../../../enum/sort.enum'
import { useTranslation } from 'react-i18next'
import './warranties-table.scss'

interface WarrantiesTableProps {
    vin: string
}

export const WarrantiesTable = (props: WarrantiesTableProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { warranties, order, sort } = useSelector((state: State) => state.warranties)
    const { page, pageSize, count } = useSelector((state: State) => state.warranties.pagination)

    const onPageChange = async (page: number) => {
        dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_CHANGE_PAGE, page: page })
        await getWarranties(props.vin)
    }

    const onOrderChange = async (column: string) => {
        let direction = Sort.ASC

        if (order === column) {
            if (sort === Sort.ASC) {
                direction = Sort.DESC
            } else if (sort === Sort.DESC) {
                column = ``
                direction = Sort.EMPTY
            }
        }

        dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_CHANGE_ORDER, orderBy: column, sort: direction })
        await getWarranties(props.vin)
    }

    const columns = [
        {
            canOrder: true,
            caption: t('warranties_table_issue_title'),
            render: (row: any) => row.id,
            name: `id`,
            width: 154,
        },
        {
            canOrder: true,
            caption: t('warranties_table_number_of_photos_title'),
            render: (row: any) => row.photoCount,
            name: `photo_count`,
            width: 194,
        },
        {
            canOrder: true,
            caption: t('warranties_table_creation_title'),
            render: (data: any) => createFormattedDateString(data.created),
            name: `created`,
            width: 174,
        },
        {
            canOrder: false,
            caption: t('warranties_table_type_title'),
            render: (data: any) => (data.segment == 1 ? t('segment_automobile_title') : data.segment == 2 ? t('segment_motorcycle_title') : t('segment_marine_title')),
            name: `segment`,
            width: 174,
        },
        {
            canOrder: true,
            caption: t('warranties_table_dealership_title'),
            render: (row: any) => row.serviceCode + ' - ' + row.serviceName,
            name: `service_code`,
            width: 210,
        },
        {
            canOrder: true,
            caption: t('warranties_table_country_title'),
            render: (row: any) => row.country,
            name: ` country.name`,
            width: 130,
        },
    ]

    return <Table className="warranties-table" columns={columns} dataset={warranties !== null && warranties !== undefined ? warranties : []} pagination={{ page: page, pageSize: pageSize, count: count, onPageChange: onPageChange }} order={{ orderBy: order, sort: sort }} onOrderChange={onOrderChange} onRowClick={(row) => navigate(`/warranty/${row.id}`)} />
}
