import { MouseEventHandler } from 'react'
import { Modal } from '../../../component/modal'
import { Photo } from '../../../entity'
import { createFormattedDateTimeString } from '../../../helper'
import { useTranslation } from 'react-i18next'
import './photo-info-modal.scss'

interface PhotoInfoModalProps {
    photo: Photo
    onCloseClick?: MouseEventHandler
    onCancelClick?: MouseEventHandler
    onOkClick?: MouseEventHandler
}

export const PhotoInfoModal = (props: PhotoInfoModalProps) => {
    const { t } = useTranslation()

    return (
        <Modal title={t('photo_info_modal_title') + ' ' + props.photo.id} onCloseClick={props.onCloseClick} onCancelClick={props.onCancelClick} onOkClick={props.onOkClick}>
            <div className="photo-info-modal__row">
                <label className="photo-info-modal__title">{t('photo_info_modal_reporter_title')}</label>
                <label className="photo-info-modal__description">{props.photo.sender?.firstName + ' ' + props.photo.sender?.lastName}</label>
            </div>
            <div className="photo-info-modal__row">
                <label className="photo-info-modal__title">{t('photo_info_modal_taken_title')}</label>
                <label className="photo-info-modal__description">{createFormattedDateTimeString(props.photo.metadata?.mobileCreated ?? 0)}</label>
            </div>
            <div className="photo-info-modal__row">
                <label className="photo-info-modal__title">{t('photo_info_modal_upload_title')}</label>
                <label className="photo-info-modal__description">{createFormattedDateTimeString(props.photo.created)}</label>
            </div>
            <label className="photo-info-modal__description">{t('photo_info_modal_modification_title')}</label>
            <div className="photo-info-modal__row">
                <label className="photo-info-modal__title">{t('photo_info_modal_modified_title')}</label>
                <label className="photo-info-modal__description">{props.photo.modifier?.firstName + ' ' + props.photo.modifier?.lastName}</label>
            </div>
            <div className="photo-info-modal__row">
                <label className="photo-info-modal__title">{t('photo_info_modal_modification_time_title')}</label>
                <label className="photo-info-modal__description">{createFormattedDateTimeString(props.photo.updated)}</label>
            </div>
        </Modal>
    )
}
