import { LoaderFunctionArgs } from 'react-router-dom'
import { store } from '../../store'
import { GET_ALL_USER_ACTIONS } from '../../action/users/users.constants'
import { getAllUser, getDealerships } from '../../action'

export const userManagemenetPageLoader = async (args: LoaderFunctionArgs) => {
    store.dispatch({ type: GET_ALL_USER_ACTIONS.GET_ALL_USER_SEARCH, search: '' })

    const role = args.params.role as string

    store.dispatch({ type: GET_ALL_USER_ACTIONS.GET_ALL_USER_CHANGE_ROLE, role: role != undefined ? (role == 'dealers' ? 2 : role == 'users' ? 3 : role == 'reviewer' ? 4 : 0) : null })

    await getAllUser()

    await getDealerships()

    return null
}
