import { MouseEvent, MouseEventHandler, ChangeEvent, useRef, useState, createRef, useEffect } from 'react'
import { Modal } from '../../../component/modal'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_USER_ACTIONS } from '../../../action/users/users.constants'
import { EmailField, TextField } from '../../../component/field'
import { State } from '../../../state'
import { useTranslation } from 'react-i18next'
import { Checkbox, Dropdown } from '../../../component'
import { createUser, deleteUser, updateUserStatus } from '../../../action'
import { User } from '../../../entity'
import { UserType } from '../../../enum/user-type.enum'
import { Language } from '../../../enum/language.enum'
import './add-user-modal.scss'
import { Status } from '../../../enum/status.enum'
import { validateEmail } from '../../../helper/validate.helper'

interface AddUserModalProps {
    user: User | null
    role: string | undefined
    onModalClose?: CallableFunction

    onCloseClick?: MouseEventHandler
    onCancelClick?: MouseEventHandler
    onOkClick?: MouseEventHandler
}

export const AddUserModal = (props: AddUserModalProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const authenticatedUser = useSelector((state: State) => state.session.authenticatedUser)

    const { dealerships } = useSelector((state: State) => state.dealerships) //TODO nem az összes lapozás vagy hogyan
    const { createUserForm } = useSelector((state: State) => state.users)
    const formElement = createRef<HTMLFormElement>()

    const [role, setRole] = useState(-1)
    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [languageError, setLanguageError] = useState('')
    const [dealershipError, setDealershipError] = useState('')
    const [roleError, setRoleError] = useState('')

    useEffect(() => {
        if (props.role != undefined) {
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_ROLE, roleId: props.role == 'dealers' ? UserType.DEALER : props.role == 'users' ? UserType.USER : UserType.REVIEWER })
        }

        if (authenticatedUser?.role == UserType.DEALER) {
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_DEALERSHIP, dealership: authenticatedUser.dealership?.id })
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_ROLE, roleId: UserType.USER })
        }

        if (props.user) {
            setRole(props.user.role)
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_USER_ID, userId: props.user.id })
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_FIRST_NAME, firstName: props.user.firstName })
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_LAST_NAME, lastName: props.user.lastName })
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_EMAIL, email: props.user.email })
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_ROLE, roleId: props.user.role })
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_DEALERSHIP, dealership: props.user.dealership?.id })
            dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_LANGUAGE, language: Language.fromString(props.user.language) })
        }
    }, [])

    const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_FIRST_NAME, firstName: event.target.value })
    }

    const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_LAST_NAME, lastName: event.target.value })
    }

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_EMAIL, email: event.target.value })
    }

    const onRoleChange = (roleId: any) => {
        setRoleError('')

        setRole(roleId)
        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_ROLE, roleId: roleId })
    }

    const onDealershipChange = (dealershipId: any) => {
        setDealershipError('')

        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_DEALERSHIP, dealership: dealershipId })
    }

    const onLanguageChange = (languageId: any) => {
        setLanguageError('')

        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CHANGE_LANGUAGE, language: languageId })
    }

    const onStatusChange = async () => {
        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CLEAR })

        if (props.user!!.status == Status.DEACTIVATED) {
            await updateUserStatus(props.user!!.id, Status.ACTIVE)

            if (props.onModalClose) {
                props.onModalClose()
            }
        } else {
            if (props.onModalClose) {
                props.onModalClose(true)
            }
        }
    }

    const onSaveButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (!validateFileds()) {
            return
        }

        if (!formElement.current?.reportValidity()) {
            return
        }

        await createUser()

        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CLEAR })

        if (props.onModalClose) {
            props.onModalClose()
        }
    }

    const validateFileds = () => {
        setFirstNameError('')
        setLastNameError('')
        setEmailError('')
        setLanguageError('')
        setDealershipError('')
        setRoleError('')

        if (createUserForm.firstName.value.length == 0) {
            setFirstNameError(t('error_add_user_first_name_empty'))
            return false
        }

        if (createUserForm.lastName.value.length == 0) {
            setLastNameError(t('error_add_user_last_name_empty'))
            return false
        }

        const emailValidation = validateEmail(createUserForm.email.value)
        if (emailValidation != null) {
            setEmailError(t(emailValidation))
            return false
        }

        if (createUserForm.role == 0) {
            setRoleError(t('error_add_user_select_role'))
            return false
        }

        if (props.role == 'reviewer' || role == UserType.REVIEWER) {
            if (createUserForm.language == null) {
                setLanguageError(t('error_add_user_select_language'))
                return false
            }
        } else {
            if (createUserForm.dealership == null) {
                setDealershipError(t('error_add_user_select_dealership'))
                return false
            }
        }

        return true
    }

    const onDeleteButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        await deleteUser(props.user!!.id, false)

        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CLEAR })

        if (props.onModalClose) {
            props.onModalClose()
        }
    }

    return (
        <Modal title={props.user ? t('user_managment_edit_user_title') : t('user_managment_add_user_title')} onCloseClick={props.onCloseClick} onCancelClick={props.onCancelClick} onOkClick={props.onOkClick}>
            <form action="" className="add-user-modal" ref={formElement}>
                <TextField field={createUserForm.firstName} onChange={onFirstNameChange} placeholder={t('user_managment_add_user_first_name_placeholder')} />
                <p className={`error-message`}>{firstNameError.length > 0 ? firstNameError : ''}</p>

                <TextField field={createUserForm.lastName} onChange={onLastNameChange} placeholder={t('user_managment_add_user_last_name_placeholder')} />
                <p className={`error-message`}>{lastNameError.length > 0 ? lastNameError : ''}</p>

                <EmailField field={createUserForm.email} onChange={onEmailChange} placeholder={t('login_email_placeholder')} />
                <p className={`error-message`}>{emailError.length > 0 ? emailError : ''}</p>

                {props.user ? (
                    <div className="check-box-group">
                        <div className="check-box-container">
                            <label>Active</label>

                            <Checkbox
                                checked={props.user.status === Status.ACTIVE}
                                id="status"
                                onChange={() => {
                                    onStatusChange()
                                }}
                            />
                        </div>
                    </div>
                ) : null}

                {props.role == undefined ? (
                    <>
                        <Dropdown
                            selectItemTitle={t('user_managment_add_user_select_role_title')}
                            value={createUserForm.role}
                            items={
                                authenticatedUser?.role == UserType.DEALER && authenticatedUser?.id != props.user?.id
                                    ? [{ id: UserType.USER, value: t('user_type_user_title') }]
                                    : authenticatedUser?.role == UserType.DEALER && authenticatedUser?.id == props.user?.id
                                    ? [{ id: UserType.DEALER, value: t('user_type_dealer_title') }]
                                    : [
                                          { id: UserType.DEALER, value: t('user_type_dealer_title') },
                                          { id: UserType.USER, value: t('user_type_user_title') },
                                          { id: UserType.REVIEWER, value: t('user_type_reviewer_title') },
                                      ]
                            }
                            error={''}
                            onChange={onRoleChange}
                        />
                        <p className={`error-message`}>{roleError.length > 0 ? roleError : ''}</p>
                    </>
                ) : null}

                {props.role == 'reviewer' || role == UserType.REVIEWER ? (
                    <>
                        <Dropdown
                            selectItemTitle={t('dealerships_add_dealership_select_language_title')}
                            value={createUserForm.language}
                            items={[
                                { id: Language.HUNGARIAN, value: t('language_hungarian_title') },
                                { id: Language.ENGLISH, value: t('language_english_title') },
                                { id: Language.ROMANIAN, value: t('language_romanian_title') },
                                { id: Language.CROATIAN, value: t('language_croatian_title') },
                                { id: Language.CZECH, value: t('language_czech_title') },
                                { id: Language.SLOVENIAN, value: t('language_slovenian_title') },
                                { id: Language.SLOVAK, value: t('language_slovak_title') },
                            ]}
                            error={''}
                            onChange={onLanguageChange}
                        />
                        <p className={`error-message`}>{languageError.length > 0 ? languageError : ''}</p>
                    </>
                ) : (
                    <>
                        <Dropdown
                            selectItemTitle={t('user_managment_add_user_select_dealership_title')}
                            value={createUserForm.dealership}
                            items={
                                authenticatedUser?.role == UserType.DEALER
                                    ? [
                                          {
                                              id: authenticatedUser?.dealership?.id ?? 0,
                                              value: authenticatedUser?.dealership?.serviceCode + ' - ' + authenticatedUser?.dealership?.serviceName,
                                          },
                                      ]
                                    : dealerships.map((dealership) => ({
                                          id: dealership.id,
                                          value: dealership.serviceCode + ' - ' + dealership.serviceName,
                                      }))
                            }
                            error={''}
                            onChange={onDealershipChange}
                        />
                        <p className={`error-message`}>{dealershipError.length > 0 ? dealershipError : ''}</p>
                    </>
                )}

                <div className="add-user-modal__buttons">
                   {/* {props.user ? (
                        <button className="button button--secondary secondary" onClick={onDeleteButtonClick}>
                            {t('user_managment_add_user_delete_button')}
                        </button>
                   ) : null} */}

                    <button className="button" onClick={onSaveButtonClick}>
                        {t('user_managment_add_user_save_button')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}
