import { useDispatch, useSelector } from 'react-redux'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MessageModal } from '../../component/modal'
import { ChangePasswordModal } from './component/change-password-modal'
import { deleteUser } from '../../action'
import { State } from '../../state'
import { CHANGE_PASSWORD_ACTIONS } from '../../action/users/users.constants'
import './profile.page.scss'
import { Language } from '../../enum/language.enum'

interface ProfilePageProps {}

export const ProfilPage = (props: ProfilePageProps) => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()

    const authenticatedUser = useSelector((state: State) => state.session.authenticatedUser)

    const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false)
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false)

    const onChangePasswordButtonClick = async (event: MouseEvent) => {
        event.preventDefault()
        setChangePasswordModalVisible(!changePasswordModalVisible)
    }

    const onDeleteUserButtonClick = async (event: MouseEvent) => {
        event.preventDefault()
        setDeleteUserModalVisible(!deleteUserModalVisible)
    }

    console.log(i18n.language)

    return (
        <main className="page page-profile">
            <div className="main-div">
                <div className="navigation-bar-div">
                    <h1>{t('profile_title')}</h1>
                    <p>{t('profile_description')}</p>
                </div>

                <div className="action-bar-div">
                    <button className="button" onClick={onChangePasswordButtonClick}>
                        {t('profile_change_password_button')}
                    </button>

                    <button className="button button--secondary secondary" onClick={onDeleteUserButtonClick}>
                        {t('profile_delete_button')}
                    </button>
                </div>

                <div className="terms-div">
                    <h2> {t('terms_title')}</h2>

                    <iframe className="terms-of-use-frame" src={`terms/terms_${Language.fromLanguageCode(i18n.language)}.html`}></iframe>
                </div>

                <div className="terms-div">
                    <h2> {t('login_privacy_policy_button')}</h2>

                    <iframe className="terms-of-use-frame" src={`privacy_profile/privacy_${Language.fromLanguageCode(i18n.language)}.html`}></iframe>
                </div>
            </div>

            {deleteUserModalVisible ? (
                <MessageModal
                    title={t('profile_delete_model_title')}
                    message={t('profile_delete_model_message')}
                    okTitle={t('button_delete')}
                    onCloseClick={() => {
                        setDeleteUserModalVisible(!deleteUserModalVisible)
                    }}
                    onCancelClick={() => {
                        setDeleteUserModalVisible(!deleteUserModalVisible)
                    }}
                    onOkClick={async () => {
                        await deleteUser(authenticatedUser!!.id, true)

                        setDeleteUserModalVisible(!deleteUserModalVisible)
                    }}
                />
            ) : null}

            {changePasswordModalVisible ? (
                <ChangePasswordModal
                    onModalClose={() => {
                        setChangePasswordModalVisible(!changePasswordModalVisible)
                    }}
                    onCloseClick={() => {
                        dispatch({ type: CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_CLEAR })

                        setChangePasswordModalVisible(!changePasswordModalVisible)
                    }}
                />
            ) : null}
        </main>
    )
}
