export const handleServerErrors = (code: number) => {
    switch (code) {
        case 20:
            return `error_unauthorized_message`
        case 30:
            return `error_forbidden`
        case 40:
            return `error_too_many_request`
        case 100001:
            return `error_user_not_found`
        case 100002:
            return `error_missing_password`
        case 100003:
            return `error_wrong_password`
        case 100004:
            return `error_user_excluded`
        case 100005:
            return `error_user_already_exists`
        case 100006:
            return `error_user_deactivated`
        case 200001:
            return `error_dealership_already_exist`
        case 200002:
            return `error_dealership_not_found`
        case 300001:
            return `error_warranty_not_found`
        case 400001:
            return `error_photo_not_found`
        default:
            return code
    }
}
