import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { ChangeEvent, MouseEvent, KeyboardEvent, useEffect, useState } from 'react'
import { SearchField } from '../../component/field'
import { exporUsers, getAllUser, updateUserStatus } from '../../action'
import { UserTable } from './component'
import { useParams } from 'react-router-dom'
import { CREATE_USER_ACTIONS, GET_ALL_USER_ACTIONS } from '../../action/users/users.constants'
import { AddUserModal } from './component/add-user-modal'
import { useTranslation } from 'react-i18next'
import { User } from '../../entity'
import { MessageModal } from '../../component/modal'
import { Status } from '../../enum/status.enum'
import { UserType } from '../../enum/user-type.enum'
import './user-management.page.scss'

interface UserManagementPageProps {}

export const UserManagementPage = (props: UserManagementPageProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { role } = useParams()

    const authenticatedUser = useSelector((state: State) => state.session.authenticatedUser)
    const { createUserStarted, search } = useSelector((state: State) => state.users)
    const [addUserModalVisible, setAddUserModalVisible] = useState(false)
    const [deactivateUserModalVisible, setDeactivateUserModalVisible] = useState(false)

    const [selectedUser, setSelectedUser] = useState(null as User | null)

    useEffect(() => {}, [])

    const onSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        dispatch({ type: GET_ALL_USER_ACTIONS.GET_ALL_USER_SEARCH, search: event.target.value })

        if (event.target.value.length == 0) {
            await getAllUser()
        }
    }

    const onSearchButtonClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()

        await getAllUser()
    }

    const onExportClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()

        await exporUsers()
    }

    const onAddUserButtonClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()
        setAddUserModalVisible(!addUserModalVisible)
    }

    return (
        <main className="page page-user-management">
            <div className="main-div">
                <div className="navigation-bar-div">
                    <div className="navigation-bar-title-div">
                        <h1>{role == undefined ? t('user_managment_title') : role == 'dealers' ? 'Dealers' : role == 'users' ? 'Users' : 'Reviewers'}</h1>
                        <p>{t('user_managment_description')}</p>
                    </div>
                    <div className="navigation-bar-button-div">
                        {authenticatedUser?.role == UserType.ADMIN || authenticatedUser?.role == UserType.DEALER ? (
                            <button className="button" onClick={onExportClick}>
                                {t('user_managment_export_button')}
                            </button>
                        ) : null}

                        <button className="button" onClick={onAddUserButtonClick}>
                            {t('user_managment_add_button')}
                        </button>
                    </div>
                </div>

                <div className="search-bar-div">
                    <SearchField value={search} onSearch={onSearchButtonClick} onChange={onSearchChange} placeholder={t('search_placeholder')} />

                    <button className="button" onClick={onSearchButtonClick}>
                        {t('search_button')}
                    </button>
                </div>

                <UserTable
                    onUserSelected={(user: User) => {
                        if (user.role !== UserType.ADMIN) {
                            setSelectedUser(user)
                            setAddUserModalVisible(!addUserModalVisible)
                        }
                    }}
                />
            </div>

            {addUserModalVisible ? (
                <AddUserModal
                    user={selectedUser}
                    role={role}
                    onModalClose={(deactivate?: boolean) => {
                        if (deactivate != undefined && deactivate) {
                            setDeactivateUserModalVisible(!deactivateUserModalVisible)
                        } else {
                            setSelectedUser(null)
                        }
                        setAddUserModalVisible(!addUserModalVisible)
                    }}
                    onCloseClick={() => {
                        dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_CLEAR })

                        setSelectedUser(null)

                        setAddUserModalVisible(!addUserModalVisible)
                    }}
                />
            ) : null}

            {deactivateUserModalVisible ? (
                <MessageModal
                    title={t('user_managment_inactivate_modal_title')}
                    message={t('user_managment_inactivate_modal_message')}
                    okTitle={t('user_managment_add_user_save_button')}
                    onCloseClick={() => {
                        setSelectedUser(null)

                        setDeactivateUserModalVisible(!deactivateUserModalVisible)
                    }}
                    onCancelClick={() => {
                        setSelectedUser(null)

                        setDeactivateUserModalVisible(!deactivateUserModalVisible)
                    }}
                    onOkClick={async () => {
                        await updateUserStatus(selectedUser!!.id, Status.DEACTIVATED)

                        setSelectedUser(null)

                        setDeactivateUserModalVisible(!deactivateUserModalVisible)
                    }}
                />
            ) : null}
        </main>
    )
}
